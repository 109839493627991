import './App.css';
import LineChartComponent from './components/LineChartComponent';
function App() {


   
    return (
        <div className="App">
            <header className="App-header">
                <h1>Lukasz Piecha</h1>
                <h2>Bachelor's in Computer Science - Metropolitan University of London, 2023</h2>
                <p>First class honours in Computer Science (BSc) with a year in industry 2019 - 2023</p>
            </header>

            <div className="App-body">
                <h3>About me</h3>
                <p>My passion for technology is both deep-rooted and expansive, focusing on a wide range of skills from front-end development with React.js to back-end programming with Python and Java. </p>
                <p>I have a strong interest in cloud technologies, particularly Azure Cloud, as well as in web technologies like HTML, CSS, JavaScript, and version control through GitHub.
                </p><p>
                    My goal is to become a full-stack developer—a pursuit that aligns well with my natural inclination for taking on new challenges. This enthusiasm for learning has led me to enhance my technical skills continually.
                </p><p> I recognize that the field of technology is ever evolving, and I am committed to staying updated with new advancements and methodologies.
                </p><p>
                    Programming has always been a subject of fascination for me, and achieving a Bachelor's degree in Computer Science from the Metropolitan University of London in 2023 was a significant milestone.
                </p><p>
                    This educational background has solidified my understanding of the need to be adaptable and continually upgrade my skills to tackle specific problem-solving scenarios effectively.
                </p><p>
                    In addition to general software development, I have a keen interest in data analytics and am eager to explore this area more profoundly.
                </p><p>
                    Artificial Intelligence is another domain that piques my interest, particularly its applications in my personal projects. As a chess coach, I am in the process of incorporating a chess trainer developed through AI to enhance the learning experience.
                </p><p>
                    I've always been passionate about chess, both as a player and an educator, and consider it one of my enduring interests and hobbies.
                </p>
            </div>
            <div className="App-body">
                <h3>Skills</h3>
                <div className="skill-set">
                    {["Java", "Python", "JavaScript", "HTML", "CSS", "React", "SQL", "MongoDB", "GitHub", "PHP", "Joomla", "WordPress", "Azure Cloud"].map(skill => (
                        <p key={skill}>{skill}</p>
                    ))}
                </div>
            </div>
            <div className="App-body">
                <h3>Interests</h3>
                <p>
                    In this linear chart, I want to show my interest in technology or languages.
                </p> <p>
                    PHP was my first interest, starting in 2009 when I took some courses.
                </p> <p>
                    Along the way, I learned and developed some code while working in other areas.
                </p> <p>
                    A score of 100 doesn't mean that I worked full-time in PHP, but rather it represents the time I spent on it outside of work.
                </p> <p>
                    The interest was there, but the time spent was not as much.
                </p>
                <LineChartComponent />
                <div className="chart-caption">

                    <p>Click on the name of a programming language to show or hide its data.</p>
                </div>
                <div id="legendDiv"></div>


                <h1>Experience</h1>

                <h2>COURSEWORKS AND PROJECTS FOR METROPOLITAN UNIVERSITY OF LONDON:</h2>
                <ul>
                    <li>
                        -	Student System Management: Developed using Java and Primefaces.
                    </li><li>
                        -	Final Project: ChessBotTrainer in AI: Implemented using React.js, Java, Spring Boot, Maven, and Docker. Utilized MongoDB for database management.
                    </li><li>
                        -	Software Engineering: Gained proficiency in project proposal, project management, and communication skills.
                    </li><li>
                        -	IoT Project: Implemented a C# based smoke and gas detection alarm system.
                    </li><li>
                        -	Android Development: Created a mobile quiz app.
                    </li><li>
                        -	Wampus World: Developed in Python, implemented AI algorithms to optimize pathfinding.
                    </li><li>
                        -	Web Development: Crafted a buy-and-sell platform using Joomla, CSS, PHP, MySQL, and JavaScript. Cryptocurrency Bot: Built an automated bot in Python for trading cryptocurrency, using the Binance.com API.
                    </li></ul>

                <h2>CHESS WORKS:</h2>
                <ul>
                    <li>
                        -	Chess Coach and founder of Junior Winchester Chess Club (2017 to present)
                    </li><li>
                        -	Chess International School Instructor FIDE (International Chess Federation) (2018)
                    </li><li>
                        -	Chief Coach Hampshire Junior Chess Association. (2019 to present)
                    </li><li>
                        -	Chess tutor for schools in CSC (Chess in Schools and Communities) (2018 to present)
                    </li><li>
                        -	Founder chess platform chesshood.com (2023)
                    </li></ul>
                <h1>Before 2017</h1>
                <p>
                    Technician of body shop “Ford” Solisbury/Andover/Southampton - England (Technician car sprayer)</p>
                <p>
                    Agency for Bodyshop “BestStaff” Bedford - England (Technician car sprayer)
                </p>

                <h2>Courses</h2>
                <ul>
                    <li>- HTML/CSS and Distribution of Internet Systems (2009)</li>
                    <li>- PHP and MySQL: Basic and Advanced (2009/2010)</li>
                    <li>- SQL and Relational Databases (2010)</li>
                </ul>



                <h2>I am currently open to all job opportunities and look forward to contributing to a dynamic development team.</h2>

                <h1>Interest and Achievements </h1>
                <ul>
                    <li>
                        2nd Place in Chess Tournament, Blitz championship Alicante 2008
                    </li><li>

                        2nd Place in category mayor – Hampshire Congress – Chess
                        tournament.
                    </li><li>
                        1st place Chess Tournament for Bangla Chess Federation in London (2016)
                    </li><li>

                        1st place Chess Tournament for Bangla Chess Federation in Birmingham (2017)
                    </li><li>

                        3rd place 10th CSC London Chess Classic Weekend Under 1825 (2018)
                    </li><li>

                        3rd place 10th CSC London Chess Classic 5-Day Under 1750
                    </li><li>

                        2nd place 4th DeMontford Bell Kings Place Festival: Minor Under 145 Section
                    </li>
                </ul>
                <h3>OTHER BOARD GAMES:</h3>
                <ul>
                    <li>
                        1st place Carcassonne 4 player Carcassonne UK championship (2022)
                    </li>
                    <li>2nd place Rummicub UK championship </li>

                </ul>
            </div>
            <div>

            </div>

        </div>
    );
}

export default App;
