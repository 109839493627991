import {  Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend

} from 'chart.js';
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement,Legend);
const labelsYear = Array.from({ length: 2023 - 2009 + 1 }, (_, i) => (2009 + i).toString());
function generateLegendHtml(datasets) {
  return (
    <ul>
      {datasets.map((dataset, index) => (
        <li key={index}>
          <span style={{ backgroundColor: dataset.backgroundColor, display: 'inline-block', width: '20px', height: '20px' }}></span>
          
          {dataset.label}
        </li>
      ))}
    </ul>
  );
}
const LineChartComponent = () => {
  const data = {
    labels: labelsYear,
    datasets: [
   
      {
        label: 'PHP',
        data: [100, 70, 70, 60, 60, 34, 30, 10, 10, 10, 13, 72, 22, 74, 8],
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',

      },
      {
        label: 'JavaScript/JQuery',
        data: [null, 20, 74, 80, 62, 34, 3, 5, 10, 14, 50, 40, 40, 40, 40],
        backgroundColor: 'rgba(255, 206, 86, 0.2)',
        borderColor: 'rgba(255, 206, 86, 1)',

      },
      {
        label: 'HTML',
        data: [100, 80, 30, 30, 30, 30, 47, 30, 30, 30, 30, 30, 30, 30, 30],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
      },
      {
        label: 'CSS',
        data: [100, 60, 50, 70, 62, 75, 73, 3, 3, 3, 70, 70, 70, 70, 70],
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
      },
      {
        label: 'Mysql/SQL',
        data: [85, 55, 54, 53, 39, 30, 3, 5, 5, 14, 29, 30, 25, 68,20],
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
        borderColor: 'rgba(255, 159, 64, 1)',

      },
      {
        label: 'Joomla',

        data: [null, null, 80, 84, 89, 99, 97, null, null, null, null, null, null, null, null],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
      },
 

      {
        label: 'WordPress',
        data: [null, null, null, null, null,null, null, 100, 70, 0, 0, 58, 5, 100, 5],

        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(0, 0, 0, 0.2)',
      },

      {
        label: 'Java',
        data: [null, null, null, null, null,null, null, null, null, null, 100, 100, 90, 90, 50],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(0, 0, 0, 1)',

      },
      {
        label: 'Python',
        data: [null, null, null, null, null,null, null, null, null, null, 24, 27, 100, 54, 100],
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        borderColor: 'rgba(140, 129, 100, 1)',
      },
  
      {
        label: 'MongoDB',

        data: [null, null, null, null, null,null, null, null, null, null, null, null, 80, 80, 100],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(88, 150, 54, 1)',
      },
      {
        label: 'GitHub',
        data: [null, null, null, 50, 50,50, 50, 50, 50, 50, 50, 50, 50, 76, 97],
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
         borderColor: 'rgba(50, 50, 50, 1)',
      },
      {
        label: 'React',
        data: [null, null, null, null, null,null, null, null, null, null, null, null, 66, 86, 99],
        backgroundColor: 'rgba(255, 206, 86, 0.2)',
        borderColor: 'rgba(0, 55, 255, 1)',
      },

      {
        label: 'Azure Cloud',
        data: [null, null, null, null, null,null, null, null, null, null, null, null, 28, 23, 96],
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
        borderColor: 'rgba(30, 100, 158, 1)',
      }


    ]
  }
  const options = {
    plugins: {
      legend: { 
        display: false,
         position: 'bottom',
         responsive: true,


         maintainAspectRatio: false,
        lables: { 
         fontSize:200
        }
        },
      scales: {
        y: {
          beginAtZero: true,
        }
      }
    }
  }
  return (
    <div className='chart-container' >
       <div id="legendDiv">{generateLegendHtml(data.datasets)}
       </div>
      <Line className='myChart'
        data={data}
        options={options}
      ></Line>
      
    </div >
  );




}
export default LineChartComponent;











